import {HardwareRepository, ProductRepository} from 'IndexedDB';
import Axios from './Axios';

const hardwareRepository = new HardwareRepository();
const productRepository = new ProductRepository();

export const getCategories = async (parentId = false) => {
    const categories = await hardwareRepository.getCategories(
        !parentId ? 0 : parseInt(parentId)
    );

    if (categories.length) {
        // check if any one the categories loaded from single item breadcrumb (breadCrumbData()) then delete all categories of this parent from indexedDb and reload from server again
        let deleteAllCategories = false;
        categories.map((category) => {
            if (category.hasOwnProperty('singleBreadcrumb')) {
                // delete all categories
                deleteAllCategories = true;
            }
        });

        if (deleteAllCategories) {
            categories.map(async (category) => {
                if (category.hasOwnProperty('singleBreadcrumb')) {
                    // delete category
                    await hardwareRepository.deleteCategory(category);
                }
            });
        } else {
            return categories;
        }
    }

    const formData = new FormData();
    formData.set('parentCategoryId', !parentId ? null : parentId);
    formData.set('exclude_empty', 'true');

    const response = await Axios.instance().post(
        `sundries/categories`,
        formData
    );
    if (response.data.success) {
        await hardwareRepository.addCategories(
            response.data.sundry_categories.map((category) => {
                category.parentCategoryId =
                    category.parentCategoryId === null
                        ? 0
                        : category.parentCategoryId;
                category.itemFetched = false;

                return category;
            })
        );

        return response.data.sundry_categories;
    } else throw response.data.errors;
};

export const getItems = async (category) => {
    let items;

    const categoryDetails = await hardwareRepository.getCategory(
        parseInt(category)
    );

    if (categoryDetails.length && categoryDetails[0].itemFetched) {
        items = await hardwareRepository.getItems(parseInt(category));

        if (items && items.length) return {sundry_items: items};
    }

    const response = await Axios.instance().get(
        `sundries/itemsincategorytoplevelonly/${category}`
    );

    if (response.data.success) {
        response.data.sundry_item_brands.length &&
            (await hardwareRepository.addBrands(
                response.data.sundry_item_brands
            ));
        response.data.sundry_items.length &&
            (await hardwareRepository.addItems(response.data.sundry_items));

        if (categoryDetails.length) {
            categoryDetails[0].itemFetched = true;
            await hardwareRepository.updateCategory(categoryDetails[0]);
        }

        return {
            sundry_items: response.data.sundry_items,
            sundry_item_brands: response.data.sundry_item_brands,
        };
    } else throw response.data.errors;
};

export const getAllItems = async () => {
    const items = await hardwareRepository.getItems();

    if (items && items.length) return items;

    const response = await Axios.instance().get(`sundries/items`);

    if (response.data.success) {
        response.data.sundry_items.length &&
            (await hardwareRepository.addItems(response.data.sundry_items));

        return response.data.sundry_items;
    }
};

export const getBreadCrumb = async (categoryId) => {
    const categories = [];

    while (categoryId) {
        let category = await hardwareRepository.getCategory(
            parseInt(categoryId)
        );
        if (category.length) {
            category = category[0];
            categoryId = category.parentCategoryId;

            categories.push(category);
        } else {
            const data = await breadCrumbData(categoryId);
            categoryId = data.parentCategoryId;

            categories.push(data);
        }
    }

    return categories.reverse();
};

const breadCrumbData = async (category) => {
    const response = await Axios.instance().get(
        `sundries/breadcrumbs/category/${category}`
    );

    if (response.data.success) {
        let parentCategory = 0;
        // reverse it so parent category is on top
        const allCategoriesBread = response.data.category_breadcrumbs.map(
            (category) => {
                category.parentCategoryId = parentCategory;
                parentCategory = parseInt(category.id);
                category.itemFetched = false;
                category.singleBreadcrumb = true;
                return category;
            }
        );

        // add in indexedDb if category not present
        allCategoriesBread.map(async (category) => {
            const categoryInBrowser = await hardwareRepository.getCategory(
                parseInt(category.id)
            );
            if (categoryInBrowser.length == 0) {
                // not in indexedDb, save now
                await hardwareRepository.addCategories(category);
            }
        });

        const categoryInBrowser = await hardwareRepository.getCategory(
            parseInt(category)
        );
        const currentCategory = {
            id: parseInt(category),
            name: response.data.category_name,
            parentCategoryId: parentCategory,
            itemFetched: false,
            singleBreadcrumb: true,
            imageUrl: response.data.imageUrl,
        };
        if (categoryInBrowser.length == 0) {
            // add current category also
            await hardwareRepository.addCategories(currentCategory);
        }
        return currentCategory;
    } else throw response.data.errors;
};

export const searchItems = async (category, search) => {
    const searchResult = await hardwareRepository.getSearch(
        !category ? 0 : parseInt(category),
        search
    );

    if (searchResult.length) {
        const items = await hardwareRepository.getItemsByIds(
            searchResult[0].items
        );

        if (items.length === searchResult[0].items.length)
            return {sundry_items: items};
    }

    const formData = new FormData();
    formData.set('name', search);

    if (category) formData.set('sundry_category_id', category);

    try {
        const response = await Axios.instance().post(
            `sundries/items`,
            formData
        );

        if (response.data.success) {
            if (response.data.sundry_items.length) {
                await hardwareRepository.addItems(response.data.sundry_items);

                await hardwareRepository.addSearch({
                    search: search,
                    category: !category ? 0 : parseInt(category),
                    items: response.data.sundry_items.map((item) => item.id),
                });
            }

            return {sundry_items: response.data.sundry_items};
        }
    } catch (e) {
        return {sundry_items: []};
    }
};

export const updateFavourite = async (
    itemId,
    isNew = true,
    isProduct = false
) => {
    let lastFavourites = 0;

    if (isNew) {
        if (isProduct)
            lastFavourites = await Axios.instance().get(
                `cabinets/lastFavourites`
            );
        else
            lastFavourites = await Axios.instance().get(
                `sundries/lastFavourites`
            );

        lastFavourites =
            lastFavourites.data.success && lastFavourites.data.lastFavourites;
        lastFavourites++;
    }

    const formData = new FormData();
    formData.append('favourites', `${lastFavourites}`);

    if (isProduct) {
        const response = await Axios.instance().post(
            `cabinets/id/${itemId}/favourite`,
            formData
        );

        if (response.data.success) {
            await productRepository.updateFavourite({
                id: parseInt(itemId),
                favourites: isNew ? lastFavourites : 0,
            });
        }
    } else {
        const response = await Axios.instance().post(
            `sundries/id/${itemId}/favourite`,
            formData
        );

        if (response.data.success) {
            await hardwareRepository.updateFavourite({
                id: itemId,
                favourites: isNew ? lastFavourites : 0,
            });
        }
    }
};

export const getFavouriteItems = async () => {
    const formData = new FormData();
    formData.append('favourites', '1');

    const response = await Axios.instance().post(`sundries/items`, formData);

    if (response.data.success) {
        return {sundry_items: response.data.sundry_items};
    }
};

export const getItem = async (itemId) => {
    const item = await hardwareRepository.getItem(parseInt(itemId));

    if (item.length) return item[0];

    const formData = new FormData();
    formData.append('id', parseInt(itemId));
    const response = await Axios.instance().post(`sundries/items`, formData);
    if (response.data.success) {
        response.data.sundry_items.length &&
            (await hardwareRepository.addItems(response.data.sundry_items));

        return (
            (response.data.sundry_items.length &&
                response.data.sundry_items[0]) ||
            {}
        );
    }
};

export const getJobSundryItem = async (jobSundryitemId) => {
    const formData = new FormData();
    formData.append('id', parseInt(jobSundryitemId));
    const response = await Axios.instance().post(
        `sundries/jobsundryitem`,
        formData
    );
    if (response.data.success) {
        return (
            (response.data.job_sundry_item.length &&
                response.data.job_sundry_item[0]) ||
            {}
        );
    }
};

export const getSelectedJobSundryAttributes = async (jobSundryitemId) => {
    const formData = new FormData();
    formData.append('id', parseInt(jobSundryitemId));
    const response = await Axios.instance().post(
        `sundries/job_sundry_attributes`,
        formData
    );
    if (response.data.success) {
        if (response.data.success) {
            return response.data.job_sundry_attributes;
        }
    }

    return {};
};

export const getItemsFromJob = async (jobId) => {
    const data = {
        sundry_item_id: 1,
        job_id: jobId - 10000,
        sundry_item_quantity: 1,
    };
    const response = await Axios.instance().post(`sundries/jobitems`, data);
    return response.data;
};

export const saveItemToJob = async (formData) => {
    const response = await Axios.instance().post(
        `sundries/jobitems/save`,
        formData
    );

    return response.data;
};

export const removeItem = async (id) => {
    const formData = new FormData();
    formData.append('id', id);
    return await Axios.instance().post(`sundries/jobitems/remove`, formData);
};

let ajaxRunning = false;
let cachedData = {};

export const getAdvancedAttributes = async (sundry, attributeValuesStore) => {
    if (ajaxRunning) {
        return cachedData;
    }
    ajaxRunning = true;

    const formData = new FormData();
    formData.append('sundry_item_id', sundry);

    attributeValuesStore &&
        Object.keys(attributeValuesStore).forEach((attribute) => {
            formData.append(
                `attribute_values[${attribute}]`,
                attributeValuesStore[attribute]
            );
        });
    const response = await Axios.instance().post(
        `sundries/item/filteredattributes`,
        formData
    );
    ajaxRunning = false;

    if (response.data.success) {
        cachedData = response.data.sundry_item_filtered_attributes;
        return cachedData;
    }
};

export const getHardwareVariations = async (sundry) => {
    const formData = new FormData();
    formData.append('sundry_item_id', sundry);

    const response = await Axios.instance().post(
        `sundries/itemvariations`,
        formData
    );

    if (response.data.success) {
        return Object.values(response.data.sundry_item_variations);
    }
};
